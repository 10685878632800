import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";
import Container from "./container";
import {Link} from "gatsby";
import { Formik, Field, Form } from 'formik';

class SubmitForm extends React.Component {
    constructor(props) {
        super(props);

        if (typeof window !== `undefined`) {
            let UTM = ['utm_campaign', 'utm_source', 'utm_medium', 'utm_content', 'utm_term'];

            let getUrlParams = () => {
                let params = {},
                    hash;
                let hashes = decodeURI(window.location.href).replace(/\+/g, " ").slice(window.location.href.indexOf('?') + 1).split('&');
                for (let i = 0; i < hashes.length; i++) {
                    hash = hashes[i].split('=');
                    params[hash[0]] = decodeURI(hash[1]);
                }
                return params;
            };

            let urlParam = getUrlParams();
            for (let prop in urlParam) {
                if (UTM.indexOf(prop) >= 0) {
                    localStorage.setItem(prop, urlParam[prop]);
                }
            }

            this.state = {
                sent: false,
                utmCampaign: localStorage.getItem('utm_campaign') || '',
                utmSource: localStorage.getItem('utm_source') || '',
                utmMedium: localStorage.getItem('utm_medium') || '',
                utmContent: localStorage.getItem('utm_content') || '',
                utmTerm: localStorage.getItem('utm_term') || ''
            };
        } else {
            this.state = {
                sent: false,
                utmCampaign: '',
                utmSource: '',
                utmMedium: '',
                utmContent: '',
                utmTerm:  ''
            };
        }
    }

    render() {
        return (
            <section id="register" className="relative py-12 md:py-16 xl:py-24">
                <StaticImage src="../images/bg.jpg" alt="" className="!absolute !inset-0 !z-[-1]" />

                <Container>
                    <div className="max-w-3xl mx-auto">
                        <h3 className="text-white text-2xl font-semibold mb-8 md:text-4xl xl:mb-12">Apply!</h3>

                        {this.state.sent && (<div className="text-green bg-black p-8 mb-12">Your message has been sent!</div>)}

                        {!this.state.sent && (<Formik
                            initialValues={{
                                firstName: '',
                                lastName: '',
                                email: '',
                                phone: '',
                                school: '',
                                city: '',
                                role: '',
                                terms: false,
                                utmCampaign: this.state.utmCampaign,
                                utmSource: this.state.utmSource,
                                utmMedium: this.state.utmMedium,
                                utmContent: this.state.utmContent,
                                utmTerm: this.state.utmTerm
                            }}
                            onSubmit={async (values) => {
                                const formData = new FormData();

                                Object.entries(values).forEach(([key, value]) => {
                                    formData.set(key, value);
                                });

                                const response = await fetch('/send.php', {
                                    method: 'POST',
                                    body: formData
                                });

                                const json = await response.json();

                                if (json.status === 'ok') {
                                    this.setState({sent: true});

                                    if (typeof window !== `undefined`) {
                                        document.getElementById('formularz').scrollIntoView();
                                    }
                                } else {
                                    alert ('There was a problem sending your message. Please try again later.');
                                }
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <div className="space-y-12">
                                        <div className="space-y-6">
                                            <div>
                                                <label htmlFor="input-firstName" className="block text-white leading-snug font-semibold mb-2">Name</label>
                                                <Field type="text" name="firstName" id="input-firstName" required className="bg-transparent border border-white border-opacity-50 w-full text-white rounded hover:border-opacity-100 focus:border-opacity-100 focus:ring-0 focus:border-white" />
                                            </div>

                                            <div>
                                                <label htmlFor="input-lastName" className="block text-white leading-snug font-semibold mb-2">Surname</label>
                                                <Field type="text" name="lastName" id="input-lastName" required className="bg-transparent border border-white border-opacity-50 w-full text-white rounded hover:border-opacity-100 focus:border-opacity-100 focus:ring-0 focus:border-white" />
                                            </div>

                                            <div>
                                                <label htmlFor="input-email" className="block text-white leading-snug font-semibold mb-2">Mail</label>
                                                <Field type="email" name="email" id="input-email" required className="bg-transparent border border-white border-opacity-50 w-full text-white rounded hover:border-opacity-100 focus:border-opacity-100 focus:ring-0 focus:border-white" />
                                            </div>

                                            <div>
                                                <label htmlFor="input-phone" className="block text-white leading-snug font-semibold mb-2">Phone</label>
                                                <Field type="text" name="phone" id="input-phone" required className="bg-transparent border border-white border-opacity-50 w-full text-white rounded hover:border-opacity-100 focus:border-opacity-100 focus:ring-0 focus:border-white" />
                                            </div>

                                            <div>
                                                <label htmlFor="input-school" className="block text-white leading-snug font-semibold mb-2">School name</label>
                                                <Field type="text" name="school" id="input-school" required className="bg-transparent border border-white border-opacity-50 w-full text-white rounded hover:border-opacity-100 focus:border-opacity-100 focus:ring-0 focus:border-white" />
                                            </div>

                                            <div>
                                                <label htmlFor="input-city" className="block text-white leading-snug font-semibold mb-2">City</label>
                                                <Field type="text" name="city" id="input-city" required className="bg-transparent border border-white border-opacity-50 w-full text-white rounded hover:border-opacity-100 focus:border-opacity-100 focus:ring-0 focus:border-white" />
                                            </div>

                                            <div>
                                                <label htmlFor="input-role" className="block text-white leading-snug font-semibold mb-2">Your role in the school</label>
                                                <Field as="select" name="role" id="input-role" required className="w-full bg-transparent border-white border-opacity-50 text-white rounded focus:ring-0 focus:border-white" style={{ backgroundImage: 'url("data:image/svg+xml,%3Csvg width=\'9\' height=\'8\' viewBox=\'0 0 9 8\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M5.36603 7C4.98113 7.66667 4.01887 7.66667 3.63397 7L0.602887 1.75C0.217987 1.08333 0.699112 0.249999 1.46891 0.249999L7.53109 0.25C8.30089 0.25 8.78202 1.08333 8.39711 1.75L5.36603 7Z\' fill=\'white\'/%3E%3C/svg%3E%0A")', backgroundSize: '0.6875rem 0.6875rem' }}>
                                                    <option value="" className="bg-white text-black">Choose:</option>
                                                    <option value="uczeń" className="bg-white text-black">Student</option>
                                                    <option value="nauczyciel" className="bg-white text-black">Teacher</option>
                                                    <option value="dyrekcja" className="bg-white text-black">Principal</option>
                                                    <option value="inne" className="bg-white text-black">Other</option>
                                                </Field>
                                            </div>
                                        </div>

                                        <div>
                                            <label htmlFor="input-terms" className="grid grid-cols-[1rem_auto] gap-4 text-sm text-white">
                                                <Field type="checkbox" name="terms" id="input-terms" required className="w-4 h-4 bg-transparent border-2 border-white border-opacity-50 text-white text-opacity-50 focus:ring-0" />
                                                <span>I confirm reading the <Link to="/regulamin" className="underline">Terms and Conditions</Link></span>
                                            </label>
                                        </div>

                                        <div>
                                            <Field type="hidden" name="utmCampaign" value={this.state.utmCampaign} />
                                            <Field type="hidden" name="utmSource" value={this.state.utmSource} />
                                            <Field type="hidden" name="utmMedium" value={this.state.utmMedium} />
                                            <Field type="hidden" name="utmContent" value={this.state.utmContent} />
                                            <Field type="hidden" name="utmTerm" value={this.state.utmTerm} />

                                            <button type="submit" disabled={isSubmitting} className="block w-full bg-black text-white text-center font-semibold p-4 text-2xl leading-none disabled:opacity-50 disabled:cursor-wait">Register your class/school</button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>)}


                    </div>
                </Container>
            </section>
        )
    }
}

export default SubmitForm;