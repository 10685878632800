import * as React from 'react';
import { Helmet } from 'react-helmet';
import Container from "./container";
import logo from "../images/logo.svg";
import accenture from "../images/acc-mini.png";
import icons from "../images/icons.svg";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from 'gatsby';

class Layout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mobileMenuVisible: false,
        };
    }

    render () {
        let mobileMenuClasses = this.state.mobileMenuVisible ?
            'fixed inset-0 z-50 xl:hidden opacity-100 transition-opacity' :
            'fixed inset-0 z-50 xl:hidden opacity-0 transition-opacity pointer-events-none';

        return (
            <div className="antialiased">
                <Helmet>
                    <html lang="en" className="scroll-smooth" />
                    <title>#ChangeSchapers</title>
                </Helmet>

                <header className="absolute inset-x-0 top-0 z-10 bg-white">
                    <Container>
                        <div className="flex py-6 md:py-8 justify-between items-center">
                            <div><Link to="/en"><img src={logo} alt="Accenture" className="block w-auto h-[29px] md:h-[45px]" /></Link></div>

                            <nav className="hidden xl:flex gap-6 font-semibold group pointer-events-none">
                                <a className="text-black group-hover:text-opacity-50 hover:!text-opacity-100 pointer-events-auto transition-colors" href="/en#changeshapers">ChangeShapers</a>
                                <a className="text-black group-hover:text-opacity-50 hover:!text-opacity-100 pointer-events-auto transition-colors" href="/en#about">About</a>
                                <a className="text-black group-hover:text-opacity-50 hover:!text-opacity-100 pointer-events-auto transition-colors" href="/en#organizations">Organizations</a>
                                <a className="text-black group-hover:text-opacity-50 hover:!text-opacity-100 pointer-events-auto transition-colors" href="https://rekrutacja.changeshapers.pl">Recruitment</a>
                                <a className="text-black group-hover:text-opacity-50 hover:!text-opacity-100 pointer-events-auto transition-colors" href="/en#register">Register your school</a>
                                <a className="text-black group-hover:text-opacity-50 hover:!text-opacity-100 pointer-events-auto transition-colors" href="/en#team">Project team</a>
                            </nav>

                            <button className="block xl:hidden w-9 space-y-2" onClick={() => this.setState({mobileMenuVisible: true})}>
                                <span className="block h-0.5 bg-black"></span>
                                <span className="block h-0.5 bg-black"></span>
                                <span className="block h-0.5 bg-black"></span>
                            </button>
                        </div>
                    </Container>
                </header>

                <main>
                    { this.props.children }
                </main>

                <footer className="bg-purple-3 py-8 xl:py-16">
                    <Container>
                        <div className="grid gap-4 mb-10 md:grid-cols-[2.5rem_auto] xl:gap-x-24 xl:grid-cols-[2.5rem_1fr_1fr] xl:items-center">
                            <div><a href="https://www.accenture.com"><img src={accenture} alt="Accenture" className="block w-10 h-auto mx-auto" /></a></div>

                            <nav className="text-sm text-white flex flex-col items-center gap-4 md:flex-row md:justify-center md:gap-x-24 xl:justify-start">
                                <a href="mailto:kontakt@changeshapers.pl">Contact us</a>
                            </nav>

                            {/* flex justify-center gap-4 text-white pt-4 md:col-span-2 md:justify-start md:gap-8 xl:col-span-1 xl:justify-end xl:pt-0 */}
                            <div className="flex justify-center pt-4 md:justify-start md:col-span-2 xl:col-span-1 xl:justify-end xl:pt-0">
                                <div className="">
                                    <div className="text-sm text-center text-white uppercase mb-4 md:text-left">Follow us</div>
                                    <div className="flex justify-center gap-4 text-white md:justify-start md:gap-8">
                                        <a href="https://www.linkedin.com/company/accenturepoland/">
                                            <svg className="block w-8 h-8 fill-transparent">
                                                <use xlinkHref={ icons + '#social-linkedin'} />
                                            </svg>
                                        </a>
                                        <a href="https://twitter.com/AccenturePolska">
                                            <svg className="block w-8 h-8 fill-transparent">
                                                <use xlinkHref={ icons + '#social-twitter'} />
                                            </svg>
                                        </a>
                                        <a href="https://www.facebook.com/AccentureKariera/">
                                            <svg className="block w-8 h-8 fill-transparent">
                                                <use xlinkHref={ icons + '#social-facebook'} />
                                            </svg>
                                        </a>
                                        <a href="https://www.instagram.com/accenturepolska/?hl=pl">
                                            <svg className="block w-8 h-8 fill-transparent">
                                                <use xlinkHref={ icons + '#social-instagram'} />
                                            </svg>
                                        </a>
                                        <a href="https://www.youtube.com/user/AccenturePoland">
                                            <svg className="block w-8 h-8 fill-transparent">
                                                <use xlinkHref={ icons + '#social-youtube'} />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border-t border-[#444443] pt-10 grid gap-6 xl:grid-cols-2">
                            <nav className="text-white underline text-xs flex justify-center flex-wrap gap-x-9 xl:justify-start">
                                <Link to="/regulamin">Terms</Link>
                                <Link to="/polityka-prywatnosci">Privacy Policy</Link>
                            </nav>

                            <div className="text-white text-xs text-center xl:text-right">&nbsp;</div>
                        </div>
                    </Container>
                </footer>

                <div className={mobileMenuClasses}>
                    <StaticImage src="../images/bg.jpg" alt="" className="!absolute !inset-0 !z-[-1]" />
                    <button type="button" className="absolute right-0 top-0 text-6xl text-white w-16 leading-none z-10" onClick={() => this.setState({mobileMenuVisible: false})}>&times;</button>
                    <div className="absolute inset-0 flex justify-center items-center">
                        <nav className="flex flex-col items-center text-center font-semibold text-white gap-8 text-lg leading-none">
                            <a href="/en#changeshapers" onClick={() => this.setState({mobileMenuVisible: false})}>ChangeShapers</a>
                            <a href="/en#about" onClick={() => this.setState({mobileMenuVisible: false})}>About</a>
                            <a href="/en#organizations" onClick={() => this.setState({mobileMenuVisible: false})}>Organizations</a>
                            <a href="https://rekrutacja.changeshapers.pl">Recruitment</a>
                            <a href="/en#register" onClick={() => this.setState({mobileMenuVisible: false})}>Register your school</a>
                            <a href="/en#team" onClick={() => this.setState({mobileMenuVisible: false})}>Project team</a>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
}

export default Layout;