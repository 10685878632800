import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from '../components/layout-en';
import Container from '../components/container';
import icons from '../images/icons.svg';
import ChangeShapers from "../components/chengeshapers-en";
import SubmitForm from "../components/form-en";

const EnIndexPage = () => (
    <Layout>
        <section className="relative">
            <StaticImage src="../images/bg.jpg" alt="" className="!absolute !inset-0 !z-[-1]"  />
            <Container>
                <div className="flex flex-col min-h-[95vh] justify-end pb-11 md:pb-14 xl:pb-20 pt-[4.8125rem] md:pt-[6.8125rem]">
                    <h1 className="text-white text-4xl font-semibold mb-4 md:text-6xl xl:text-8xl">#ChangeShapers</h1>
                    <h2 className="text-white text-2xl font-serif mb-6 md:text-4xl xl:text-5xl md:mb-9">Engagement matters</h2>

                    <ul className="text-white space-y-2 xl:max-w-2xl">
                        <li className="pl-9 bg-list-check bg-no-repeat bg-left-top">Unique development program based on the peer education formula</li>
                        <li className="pl-9 bg-list-check bg-no-repeat bg-left-top">First-hand, practical knowledge of all areas of study</li>
                        <li className="pl-9 bg-list-check bg-no-repeat bg-left-top">Inspirational offline or online meetings</li>
                    </ul>
                </div>
            </Container>
        </section>

        <ChangeShapers />

        <section id="about">
            <Container>
                <div className="py-12 md:py-16 xl:grid xl:grid-cols-12 xl:gap-12">
                    <p className="font-semibold text-xl mb-6 md:text-2xl md:leading-7 xl:col-span-5">The idea behind <span className="text-purple-1">#ChangeShapers</span> is to implement a program that, through inspiring meetings based on peer education, supports the development of active students, while giving practical education and invaluable knowledge about all aspects of studying to representatives of secondary schools from all over Poland.</p>
                    <p className="leading-7 md:text-lg md:leading-8 xl:col-span-2 xl:col-span-7">We strengthen the potential of Young Talents from selected student organizations, student unions, science clubs and associations through a dedicated series of trainings that prepare to conduct meetings. We also create a space for sharing knowledge, experiences, and practical experiences of the study period. Representatives of secondary schools and students from Ukraine, who are the addressees of the project, can draw inspiration, motivation, as well as get to know the real everyday life and unlimited possibilities offered by the time of studies.</p>
                </div>
            </Container>
        </section>

        <section id="organizations" className="bg-light-gray py-12 md:py-16">
            <Container>
                <h3 className="text-2xl font-semibold mb-8 md:text-4xl md:mb-12">Cooperating organizations</h3>

                <ul className="grid gap-2 grid-cols-1 md:grid-cols-2 xl:grid-cols-5">
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/AEGEE_Krakow.jpg" width={180} height={80} alt="Stowarzyszenie Europejskie Forum Studentów AEGEE-Kraków" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/BEST-AGH.jpg" width={180} height={80} alt="Stowarzyszenie Studentów BEST AGH Kraków" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/BEST-lodz.jpg" width={180} height={80} alt="Stowarzyszenie Studentów BEST PŁ" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/Conquest_logo.jpg" width={180} height={80} alt="Badania Rynku i Doradztwo Biznesowe ConQuest Consulting" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/ESN-EYE.jpg" width={180} height={80} alt="ESN-EYE Łódź" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/InternationalChallenge_UE_Kato_logo.jpg" width={180} height={80} alt="International Challenge" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/KIS_ALK.jpg" width={180} height={80} alt="Kozminski International Society" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/KN_Kobra.jpg" width={180} height={80} alt="Koło Naukowe Technologii i Organizacji Budowy KOBRa" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/KołoNaukoweRachunkowosci_UEK.jpg" width={180} height={80} alt="Koło Naukowe Rachunkowości Uniwersytetu Ekonomicznego w Krakowie" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/LogoURSS_rozwiniete_green.jpg" width={180} height={80} alt="Uczelniana Rada Samorządu Studentów AGH" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/NZS_UW.jpg" width={180} height={80} alt="Niezależne Zrzeszenie Studentów Uniwersytetu Warszawskiego" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/RKN_UJ.jpg" width={180} height={80} alt="Rada Kół Naukowych Uniwersytetu Jagiellońskiego" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/Samorząd_ALK.jpg" width={180} height={80} alt="Samorząd Studencki Akademii Leona Koźmińskiego" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/SF_BCC.jpg" width={180} height={80} alt="Fundacja Studenckie Forum Business Centre Club" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/SKNF_UW.jpg" width={180} height={80} alt="Studenckiego Koła Naukowego Finansów Uniwersystetu Warszawskiego" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/SS_UAM.jpg" width={180} height={80} alt="Samorząd studentów Uniwersystetu Adama Mickiewicza" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/SS_WNE_UW.jpg" width={180} height={80} alt="Samorząd Studentów Wydziału Nauk Ekonomicznych Uniwersytetu Warszawskiego" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                    <li className="p-4 md:p-8 bg-white flex justify-center items-center">
                        <div className="relative">
                            <StaticImage src="../images/logos/WomeninOrganisations_ALK.jpg" width={180} height={80} alt="Women in Organisations Akademii Leona Koźmińskiego" transformOptions={{fit: 'contain'}} backgroundColor="#ffffff" />
                        </div>
                    </li>
                </ul>
            </Container>
        </section>

        <section className="relative py-12 md:py-16 xl:py-24">
            <StaticImage src="../images/bg.jpg" alt="" className="!absolute !inset-0 !z-[-1]" />
            <Container>
                <h3 className="text-2xl text-white mb-8 font-semibold md:text-4xl md:mb-12">Benefits</h3>

                <ul className="text-white font-semibold grid grid-cols-1 gap-6 leading-5 tracking-tight md:grid-cols-2 md:gap-8 xl:grid-cols-4 xl:gap-12">
                    <li className="flex gap-6">
                        <svg className="w-12 h-12 fill-white flex-none">
                            <use xlinkHref={ icons + '#benefit-1'} />
                        </svg>
                        Networking with active students
                    </li>
                    <li className="flex gap-6">
                        <svg className="w-12 h-12 fill-white flex-none">
                            <use xlinkHref={ icons + '#benefit-2'} />
                        </svg>
                        Practical knowledge of the course of studies
                    </li>
                    <li className="flex gap-6">
                        <svg className="w-12 h-12 fill-white flex-none">
                            <use xlinkHref={ icons + '#benefit-3'} />
                        </svg>
                        Inspirations on the most effective use of studies
                    </li>
                    <li className="flex gap-6">
                        <svg className="w-12 h-12 fill-white flex-none">
                            <use xlinkHref={ icons + '#benefit-4'} />
                        </svg>
                        First-hand information about activities in student organizations, student government, and academic clubs
                    </li>
                    <li className="flex gap-6">
                        <svg className="w-12 h-12 fill-white flex-none">
                            <use xlinkHref={ icons + '#benefit-5'} />
                        </svg>
                        Q&amp;A sessions to answer any questions
                    </li>
                    <li className="flex gap-6">
                        <svg className="w-12 h-12 fill-white flex-none">
                            <use xlinkHref={ icons + '#benefit-6'} />
                        </svg>
                        Certificates of participation for your class and school
                    </li>
                    <li className="flex gap-6">
                        <svg className="w-12 h-12 fill-white flex-none">
                            <use xlinkHref={ icons + '#benefit-7'} />
                        </svg>
                        Online or offline meetings
                    </li>
                    <li className="flex gap-6">
                        <svg className="w-12 h-12 fill-white flex-none">
                            <use xlinkHref={ icons + '#benefit-8'} />
                        </svg>
                        Real impact on the world around you
                    </li>
                </ul>
            </Container>
        </section>

        <section id="rekrutacja" className="py-12 md:py-16 xl:py-24">
            <Container>
                <div className="xl:grid xl:grid-cols-12 xl:gap-12">
                    <div className="mb-8 md:mb-12 xl:col-span-5 xl:mb-0">
                        <h3 className="font-semibold text-2xl mb-4 md:text-4xl">Phases of the #ChangeShapers program</h3>
                        <p className="font-serif text-purple-2 text-lg md:text-2xl">Phases designed in such a way that all people involved - both on the high school side and the students involved in running the meetings - get the most out of the program.</p>
                    </div>
                    <div className="xl:col-span-7">
                        <ol className="space-y-2 mb-9" style={{counterReset: 'steps'}}>
                            <li className="flex border-2 border-purple-1 gap-2 p-5" style={{counterIncrement: 'steps'}}>
                                <span className="block font-semibold text-4xl text-purple-1 w-8 flex-none before:content-[counter(steps)]"></span>
                                <div>
                                    <h4 className="text-lg font-semibold mb-2.5">#ChangeShapers project call and recruitment</h4>
                                    <p className="text-sm leading-6">Accenture representatives invited the most active student organizations, local governments, associations and academic circles to join the project. Interested members of these organizations participated in a review and selection process, which led to the creation of the first group of #ChangeShapers.</p>
                                </div>
                            </li>

                            <li className="flex border-2 border-purple-1 gap-2 p-5" style={{counterIncrement: 'steps'}}>
                                <span className="block font-semibold text-4xl text-purple-1 w-8 flex-none before:content-[counter(steps)]"></span>
                                <div>
                                    <h4 className="text-lg font-semibold mb-2.5">Training &amp; Workshops</h4>
                                    <p className="text-sm leading-6">The #ChangeShapers underwent a dedicated series of trainings and workshops to adequately prepare themselves to lead the meetings with their high school friends. In addition, personal consultations were implemented to extract the maximum potential from the content prepared by #ChangeShapers.</p>
                                </div>
                            </li>

                            <li className="flex border-2 border-purple-1 gap-2 p-5" style={{counterIncrement: 'steps'}}>
                                <span className="block font-semibold text-4xl text-purple-1 w-8 flex-none before:content-[counter(steps)]"></span>
                                <div>
                                    <h4 className="text-lg font-semibold mb-2.5">Meetings with students and guests from Ukraine</h4>
                                    <p className="text-sm leading-6">The main point of the program are meetings with classes and representatives of high schools, implemented by #ChangeShapers throughout Poland in the form of offline or online. During them, practical information about studies and the realities of academic life is provided. They also have an inspirational and motivational function, showing the participants of these meetings how many opportunities the time at university offers.</p>
                                </div>
                            </li>

                            <li className="flex border-2 border-purple-1 gap-2 p-5" style={{counterIncrement: 'steps'}}>
                                <span className="block font-semibold text-4xl text-purple-1 w-8 flex-none before:content-[counter(steps)]"></span>
                                <div>
                                    <h4 className="text-lg font-semibold mb-2.5">Welcome Packs &amp; Gadgets</h4>
                                    <p className="text-sm leading-6">Appreciating the commitment and based on previous cooperation, we provide sets of dedicated gadgets for Academic Circles, Student Councils and Organizations and Associations, useful during the implementation of daily tasks and responsibilities. Additionally, each #ChangeShapers will receive a personalized welcome pack during the project. </p>
                                </div>
                            </li>

                            <li className="flex border-2 border-purple-1 gap-2 p-5" style={{counterIncrement: 'steps'}}>
                                <span className="block font-semibold text-4xl text-purple-1 w-8 flex-none before:content-[counter(steps)]"></span>
                                <div>
                                    <h4 className="text-lg font-semibold mb-2.5">Evaluation and summary of project's first edition</h4>
                                    <p className="text-sm leading-6">The project will be thoroughly debriefed by the participants, and based on the lessons learned, further #ChangeShapers activities will be planned to take place in future academic semesters.</p>
                                </div>
                            </li>

                            <li className="flex border-2 border-purple-1 gap-2 p-5" style={{counterIncrement: 'steps'}}>
                                <span className="block font-semibold text-4xl text-purple-1 w-8 flex-none before:content-[counter(steps)]"></span>
                                <div>
                                    <h4 className="text-lg font-semibold mb-2.5">Kolejne działania</h4>
                                    <p className="text-sm leading-6 mb-2">More information soon ;)</p>
                                    <p className="text-sm leading-6">Feel encouraged to follow Accenture's <a href="https://www.accenture.com" className="text-purple-1 underline">social media Accenture</a>.  We believe that we will meet again in early autumn. </p>
                                </div>
                            </li>
                        </ol>

                        <a href="#register" className="block bg-purple-1 text-white text-center font-semibold p-4 text-2xl leading-none hover:bg-purple-2">Register your class/school</a>
                    </div>
                </div>
            </Container>
        </section>

        <section className="bg-light-gray py-12 md:py-16 xl:py-24">
            <Container>
                <h3 className="text-2xl font-semibold mb-8 md:text-4xl xl:mb-12">Timeline</h3>

                <ol className="grid grid-cols-1 gap-2 md:grid-cols-3 xl:grid-cols-5">
                    <li className="p-5 border-2 border-purple-1">
                        <svg className="block w-12 h-12 fill-purple-1 mb-2.5">
                            <use xlinkHref={ icons + '#timeline-1'} />
                        </svg>
                        <p className="text-sm mb-2.5">Recruitment process</p>
                        <p className="text-sm font-semibold">March - May 2022</p>
                    </li>

                    <li className="p-5 border-2 border-purple-1">
                        <svg className="block w-12 h-12 fill-purple-1 mb-2.5">
                            <use xlinkHref={ icons + '#timeline-2'} />
                        </svg>
                        <p className="text-sm mb-2.5">Dedicated trainings and workshops</p>
                        <p className="text-sm font-semibold">May 2022</p>
                    </li>

                    <li className="p-5 border-2 border-purple-1">
                        <svg className="block w-12 h-12 fill-purple-1 mb-2.5">
                            <use xlinkHref={ icons + '#timeline-3'} />
                        </svg>
                        <p className="text-sm mb-2.5">Open Hours - individual presentation consultations before meetings</p>
                        <p className="text-sm font-semibold">May - June 2022</p>
                    </li>

                    <li className="p-5 border-2 border-purple-1">
                        <svg className="block w-12 h-12 fill-purple-1 mb-2.5">
                            <use xlinkHref={ icons + '#timeline-4'} />
                        </svg>
                        <p className="text-sm mb-2.5">Implementation of meetings with high school representatives</p>
                        <p className="text-sm font-semibold">May - June 2022</p>
                    </li>

                    <li className="p-5 border-2 border-purple-1">
                        <svg className="block w-12 h-12 fill-purple-1 mb-2.5">
                            <use xlinkHref={ icons + '#timeline-5'} />
                        </svg>
                        <p className="text-sm mb-2.5">Evaluation and summary of project's first edition</p>
                        <p className="text-sm font-semibold">June - July 2022</p>
                    </li>
                </ol>
            </Container>
        </section>

        <SubmitForm />

        <section id="team" className="bg-light-gray py-12 md:py-16 xl:py-24">
            <Container>
                <h3 className="text-2xl font-semibold mb-8 md:text-4xl xl:mb-12">Meet our team</h3>

                <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-12 lg:grid-cols-3 xl:grid-cols-4">
                    <li>
                        <StaticImage src="../images/team/ola-leszczynska.jpg" alt="Ola Leszczyńska" className="aspect-square mb-6 w-full" />
                        <h4 className="text-xl font-semibold mb-2">Ola Leszczyńska</h4>
                        <p className="text-sm">In Accenture Talent Brand Team she develops her experience in employer branding and recruitment marketing. A graduate of Psychology at the Warsaw University. Sensitive to the issues of inequality and stigmatization. Privately a mountain and climbing enthusiast and an amateur of analog photography.</p>
                    </li>
                    <li>
                        <StaticImage src="../images/team/kasia-bialecka.jpg" alt="Kasia Białecka" className="aspect-square mb-6 w-full" />
                        <h4 className="text-xl font-semibold mb-2">Kasia Białecka</h4>
                        <p className="text-sm">Member of Talent Brand Team in Accenture. A committed Kraków University of Economics and Warsaw University graduate, she spent her student years very actively: working in student organizations, being on the board of the UEK Student Parliament and studying at 3 foreign universities or speaking at conferences.</p>
                    </li>
                    <li>
                        <StaticImage src="../images/team/tosia-kuriata.jpg" alt="Tosia Kuriata" className="aspect-square mb-6 w-full" />
                        <h4 className="text-xl font-semibold mb-2">Tosia Kuriata</h4>
                        <p className="text-sm">Talent Brand Team member. She manages relations with the academic community at universities in Łódź and implements employer branding strategy activities. Campus issues are close to her heart as she continues her studies at the University of Lodz, majoring in Management with a specialization in Marketing. She is an enthusiast of gardening and macramé weaving. </p>
                    </li>
                    <li>
                        <StaticImage src="../images/team/wiola-krolewicz.jpg" alt="Wiola Królewicz" className="aspect-square mb-6 w-full" />
                        <h4 className="text-xl font-semibold mb-2">Wiola Królewicz</h4>
                        <p className="text-sm">Talent Brand Lead at Accenture. A graduate of full-time studies in Finance and Accounting at the University of Economics in Katowice and postgraduate studies in Employer Branding at the AGH University of Science and Technology. In her free time, she hikes in the mountains, goes camping and deepens her knowledge of EB and HR.</p>
                    </li>
                    <li>
                        <StaticImage src="../images/team/zespol-kapczuk.jpg" alt="Radosław Łyko" className="aspect-square mb-6 w-full" />
                        <h4 className="text-xl font-semibold mb-2">Michał Kapczuk</h4>
                        <p className="text-sm">CEO of Higher. A graduate of the Faculty of Management at the University of Warsaw. During his studies, he led the Finance Study Group at Warsaw University and created nationwide projects related to practical business education aimed at the academic community.</p>
                    </li>
                </ul>
            </Container>
        </section>

        <section className="py-12 md:py-16 xl:py-24">
            <Container>
                <div className="grid grid-cols-1 gap-12 xl:grid-cols-2 items-center">
                    <div className="grid grid-cols-2 gap-2 md:gap-6">
                        <StaticImage src="../images/about-1.jpg" alt="O Accenture" className="col-span-2 aspect-[74/51] md:aspect-[160/101]" />
                        <StaticImage src="../images/about-2.jpg" alt="O Accenture" className="aspect-[72/61] md:aspect-[154/101]" />
                        <StaticImage src="../images/about-3.jpg" alt="O Accenture" className="aspect-[72/61] md:aspect-[154/101]" />
                        <StaticImage src="../images/about-4.jpg" alt="O Accenture" className="aspect-[72/61] md:aspect-[154/101]" />
                        <StaticImage src="../images/about-5.jpg" alt="O Accenture" className="aspect-[72/61] md:aspect-[154/101]" />
                    </div>

                    <div>
                        <h3 className="text-2xl font-semibold mb-4 md:text-4xl md:mb-12">About Accenture</h3>

                        <p className="font-serif text-lg leading-6 mb-4 md:text-2xl md:leading-8">The program's lead partner is Accenture, a global company that provides professional services in digital, cloud and security technologies.</p>
                        <p className="text-sm leading-6 mb-4"><em className="italic">"There is one universal thing about Accenture employees around the world: we care deeply about what we do and the impact we have on our clients and communities. It is a personal issue for all of us".</em><br /> Julie Sweet, Chief Executive Officer Accenture</p>
                        <p className="text-sm leading-6 mb-4">With the broad experience and expertise of our experts in over 40 industries, we offer services in the areas of: Strategy & Consulting, Song, Technology and Operations using the world's largest network of high-tech centers and smart operations.</p>
                        <p className="text-sm leading-6 mb-4">We employ 699k people who harness the power of technology and human creativity in their daily work to serve clients in more than 120 countries. Accenture leverages innovation to create value and mutual success for clients, partners and communities. In Poland, Accenture has offices in Warsaw, Krakow, Lodz, Wroclaw and Katowice. They have more than 8600 employees. Get to know us!</p>
                        <p className="text-sm leading-6 mb-4"><a href="https://www.accenture.com/pl-en/careers" className="underline hover:no-underline" target="_blank" rel="noreferrer">https://www.accenture.com/pl-en/careers</a></p>
                    </div>
                </div>
            </Container>
        </section>
    </Layout>
)

export default EnIndexPage
